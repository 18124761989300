import React, {useEffect, useState} from 'react';
import './App.css';
import {faInstagram, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {faBrain, faUserGroup, faHashtag} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style/App.css';
import './style/Buttons.css';
import './style/Cards.css';
import logo from './media/unki_logo.png';
import EPFL from './media/logos/EPFL.png';
import AWS from './media/logos/AWS.png';
import Blaze from './media/logos/Blaze.png';
import Hotellerie from './media/logos/Hotellerie.png';
import Microsoft from './media/logos/Microsoft.png';
import LaForge from './media/logos/LaForge.png';
import home_image from './media/home_image_3.jpg';
import iphone from './media/home_page_screen.png';
import Button from '@mui/material/Button';
import shazam from './media/place_details.png';
import moodboards from './media/moodboards_details.png';
import maps from './media/maps_view.png';
import BackToTop from './components/BackToTop';
import { Helmet } from 'react-helmet';
import { faBolt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Alert } from '@mui/material';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';



function App() {

  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isVisibleSub, setIsVisibleSub] = useState(false);
  const [loading, setLoading] = useState(false);

  // Is there a referral code ?
  const currentUrl = window.location.href;
  
  // Create a URL object
  const url = new URL(currentUrl);
  
  // Use URLSearchParams to get the referral code
  const params = new URLSearchParams(url.search);
  console.log(params);
  const referralCode = params.get('referral'); // Change 'referral_code' to your actual query parameter


  // Waitlist form
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  // Waitlist email


  const handleSubmit = async (e : any) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_EMAIL_API}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          referralCode: referralCode ? referralCode : '',
        }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        console.log('Email envoyé avec succès!', result.response);
        setSubmitted(true);
      } else {
        console.error('Erreur lors de l\'envoi de l\'email:', result.error);
        setError(result.error);
      }
    } catch (error : any) {
      console.error('Erreur réseau ou serveur:', error);
      setError(error);
    }

    setLoading(false);
  };

  

  useEffect(() => {
    // Function to toggle visibility based on page scroll
    const toggleVisibility = () => {
      if (window.scrollY > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      if (window.scrollY > 1 && isMobile) {
        setIsVisibleSub(false);
      }
    };

    // Function to check if the user is on mobile
    const checkMobile = () => {
      if (window.innerWidth < 900) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkMobile();

    // Add scroll event listener
    window.addEventListener("scroll", toggleVisibility);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="App">
      <Helmet>
      <script type="text/javascript">
          {`(function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "omk3jhiujb");`}
      </script>
      </Helmet>
        <div className={`header ${isVisible ? 'coloured_header' : ''}`} >
          <div className='logo'>
            <a href='#'><img src={logo} alt="Unki logo"></img></a>
          </div>
          <div className='menu'>
            <a id="about-menu" href="#about-section">About</a>
            <a href="#support-section">Supporters</a>
          </div>
          <div className='social-media'>
            <a href="#footer-image">Contact</a>
            <a href="https://www.instagram.com/unkitravel/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} size='xl'></FontAwesomeIcon></a>
            <a href="https://www.linkedin.com/company/unki/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} size='xl'></FontAwesomeIcon></a>
            
          </div>
        </div>
        <div className='home'>
          <Button className='access_button' variant='contained' onClick={() => setIsVisibleSub(!isVisibleSub)}>Get early access</Button>
          <div className={`home-content ${isVisibleSub ? 'show_content' : ''}`}>
            {isVisibleSub && <FontAwesomeIcon icon={faXmark} className='close_button' onClick={() => setIsVisibleSub(!isVisibleSub)}></FontAwesomeIcon>}
            <h1>Collaboration meets inspiration</h1>
            <p>Create your dream trips together</p>
            <div className='prefinery-form-embed joinWaitList'>
            <form onSubmit={handleSubmit}>
                <TextField
                  label="Enter your email"
                  variant="standard"
                  value={email}
                  type="email"
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)} // Update email state on input change
                />
                <Button type='submit' className='primary' variant='contained' disabled={loading} >
                  {loading?  <div><CircularProgress className='button_stuff circular_motion' style={{marginRight : 10}} /><div className='button_stuff'>Join Waitlist</div></div>  : <div><FontAwesomeIcon icon={faPlus} className='button_stuff' style={{marginRight : 10}} /><div className='button_stuff'>Join Waitlist</div></div>}
                </Button>
              </form>
              {submitted && (<Alert icon={<FontAwesomeIcon icon={faCheck}/>} severity="success" className='alert'>
                              Check your email (spam included) for a little surprise from us!
                            </Alert>)} {/* Display a message on successful submission */}
              {error && (<Alert icon={<FontAwesomeIcon icon={faXmark} /> } severity="error" className="alert">
                              Unable to join the waitlist. Please try again later.
                          </Alert>)} {/* Display error message if any */}
            </div>
          </div>
          <div className='main_image_container'><img className='home_image' src={home_image} alt="Belvedere hotel in Switzerland" /></div>
          <img className={`iphone_image ${isVisibleSub ? 'outgrowIphone' : ''}`} src={iphone} alt="iPhone vision" />
        </div>
        <div id='about-section' className='about'>

          <div className='about-content'>
            <h1>Unlock the power of your journey</h1>
          </div>
          <div className='card-content'>
            <div className='main_content_center'>
            <img src={moodboards} alt="Details of a moodboard look" className='app_steps_image' />
            <img src={shazam} alt="Details of a hotel fetched with AI" className='app_steps_image' />
            <img src={maps} alt="Details of a map view" className='app_steps_image'/>
          </div>
          <div className='app_steps_container'>
            <div className='app_steps'>
              <div className='app_step_content'>
                <h2><FontAwesomeIcon icon={faBolt} style={{marginRight : 10}}/>Travel shazam</h2>
                <p>Instantly save posts from Instagram, TikTok, and more—automatically fetch detailed information like location, pricing, and descriptions. Our AI connects it to live data with just one click.</p>
              </div>
            </div>
            <div className='app_steps'>
              <div className='app_step_content'>
                <h2><FontAwesomeIcon icon={faPalette} style={{marginRight : 10}}/>Moodboards</h2>
                <p>Design your dream trip by curating moodboards from saved content. Whether it's a cozy stay or an adventurous day out, you can organize everything your way.</p>
              </div>
            </div>
            <div className='app_steps'>
              <div className='app_step_content'>
                <h2><FontAwesomeIcon icon={faUserGroup} style={{marginRight : 10}}/>Group Collaboration</h2>
                <p>Plan together effortlessly! Share your moodboards and work with your group to shape a common vision. Everyone’s input helps create the perfect itinerary.</p>
              </div>
            </div>
            <div className='app_steps'>
              <div className='app_step_content'>
                <h2><FontAwesomeIcon icon={faBrain} style={{marginRight : 10}}/>AI-Powered</h2>
                <p>Need more ideas? Our AI suggests the best spots and activities based on your preferences and location data—get instantaneous recommendations to complete your trip.</p>
              </div>
            </div>
            </div>
          </div>
          
        </div>
        <div id='support-section' className='support'>
          <div className='support-content'>
            <h1>They support us</h1>
          </div>
          <div className='supporters'>
            <img className='flex-left' src={EPFL} alt="EPFL" />
            <img className='flex-center' src={LaForge} alt="LaForge" />
            <img className='flex-right' src={Blaze} alt="Blaze" />
            <img className='flex-left' src={Hotellerie} alt="Hotellerie" />
            
            <img className='flex-centert' src={AWS} alt="AWS" />
            <img className='flex-right' src={Microsoft} alt="Microsoft" />
            
          </div>
         <a href="mailto:contact@unki.ch"><Button id="dark-button" className="primary" variant="contained" >Become a supporter</Button></a>
        </div>

        <div id="footer-contact" className='footer'>
          <img id="footer-image" src={home_image} alt="People planning a trip together." />
          <div className='overlay-background'></div>
          <div className='top-line'>
            <div className='logo'>
              <img src={logo} alt="Unki logo"></img>
            </div>
            <p>Feel free to get in touch with us to know more about the project.</p>
          </div>
          <div className='bottom-line'>
            <p>Copyright © 2024 Unki</p>
            <div className='social-media'>
              <a href="mailto:contact@unki.ch"><Button className='primary' variant='contained'>Get in touch</Button></a>
              <a href="https://www.instagram.com/unkitravel/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} size='xl'></FontAwesomeIcon></a>
              <a href="https://www.linkedin.com/company/unki/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} size='xl'></FontAwesomeIcon></a>
            </div>
          </div>
        </div>
        <BackToTop/>
      </div>
  );
}

export default App;

/*
            <p>No need to visit thousands of pages to plan your trip... Everything is here !</p>

<Card className='card'>
              <CardContent>
                <FontAwesomeIcon icon={faBrain} size='2xl'></FontAwesomeIcon>
                <h2>AI-assisted travel planning</h2>
                <p>Unki delves deep into understanding what each traveler truly desires. Whatever you are looking for, Unki curates the perfect itinerary for you.</p>
              </CardContent>
            </Card>
            <Card className='card'>
              <CardContent>
              <FontAwesomeIcon icon={faUserGroup} size='2xl'></FontAwesomeIcon>
                <h2>Group planning & coordination</h2>
                <p>Share your itinerary with your friends and family. Work together to achieve the perfect trip that suits you all.</p>
              </CardContent>
            </Card>
            <Card className='card'>
              <CardContent>
                <FontAwesomeIcon icon={faHashtag} size='2xl'></FontAwesomeIcon>
                <h2>Connected to social media</h2>
                <p>With Unki, it is as easy as sharing a post from social media into a WhatsApp or Telegram conversation. It all starts there.</p>
              </CardContent>
            </Card>

            <a href="#support-section"><Button className='primary' variant='contained'>See who supports us</Button></a>
*/